@use "@scss/utils/utils.scss" as utils;

.title {
  color: #6b6868;
  font-size: 1.5rem;
  text-transform: uppercase;

  @include utils.fontFamily("Luckiest Guy");
}

.wave {
  width: 176px;
  height: 10px;

  margin: 5px 0;
  background-size: contain;
  background-image: url("./images/separator_blueline.png");
  background-repeat: no-repeat;
  background-position: 50%;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  /* stylelint-disable-next-line selector-max-type -- Legacy. */
  & ul {
    margin-block: 0;
    /* stylelint-disable-next-line property-allowed-list -- O_o */
    padding-inline-start: 20px;
  }

  @media (--md-viewport) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
