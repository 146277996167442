@use "@scss/utils/utils.scss" as utils;

$radius: 15px;
$padding: 15px;
$border: 1px solid #e5e5e5;

.modal-container {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 100;
}

.modal {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  top: 70px;
}

.content {
  border: 1px solid #ddd;
  border-radius: $radius;
  background-color: #fff;
}

.header {
  padding: $padding;
  border-bottom: $border;
}

.title {
  color: #a0ce58;
  font-size: 1.5rem;
  text-align: center;

  @include utils.fontFamily("Baloo");
}

.body {
  display: flex;
  flex-direction: column;
  padding: $padding;
}

.footer {
  padding: $padding;
  border-top: $border;
}
