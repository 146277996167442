@use "@scss/utils/utils.scss" as utils;

$themes: (
  "default",
  "junior_prog",
  "senior_prog",
  "drone",
  "mathematics",
  "skills",
  "english",
  "dyslexia"
);

@each $theme in $themes {
  .theme_#{$theme} {
    background-image: url("./images/theme_#{$theme}.png");
  }
}

body {
  overflow-x: hidden; /* Különben megjelenne a scroll a bagoly miatt */

  @each $theme in $themes {
    &[data-theme="#{$theme}"] {
      background-image: url("./images/theme_#{$theme}.png");
    }
  }
}

:global(.sen-to-top) {
  display: flex;

  width: 50px;
  height: 50px;

  bottom: 70px;
  border-radius: 50%;
  color: #fff;
  opacity: 1;
  transform: translateY(120px);
  transition: transform 200ms, background-color 200ms;
  z-index: 100;

  &[data-visible] {
    transform: translateY(0);
  }

  @include utils.hover {
    background-color: #f79433;
  }

  @media (--sm-viewport) {
    bottom: 20px;
    transform: translateY(100px);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

body:not([data-theme="default"]) {
  & div {
    box-shadow: none;
  }

  & :global(.sni-subtitle__text) {
    color: #ffcf15;
  }
}

.main-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-container {
  margin-top: 20px;
}
