.menu {
  width: 100%;
  min-height: 60px;
  position: relative;
  padding-bottom: 16px;
  background-color: #fff;

  &::after {
    width: 100%;
    height: 16px;
    position: absolute;
    left: 0;
    bottom: -16px;
    content: "";
  }

  & .logo-container {
    display: flex;
    justify-content: center;

    @media (--sm-viewport) {
      display: block;
    }
  }

  @media (--md-viewport) {
    position: absolute;
    background-color: transparent;
  }
}

.logo {
  width: 180px;
  height: 50px;
  position: relative;
  top: 10px;
  background-image: url("./images/ugyesedni-logo-szines.svg");
  background-repeat: no-repeat;
}
