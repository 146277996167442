@use "@scss/utils/utils.scss" as utils;

:global(.pg-link) {
  color: #118acb;
  text-decoration: none;

  @include utils.hover {
    color: #f79433;
  }
}
