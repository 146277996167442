.owl-container {
  display: none;

  width: 230px;
  height: 230px;

  position: absolute;
  top: -180px;
  right: -68px;
  z-index: 100;

  @media (--md-viewport) {
    display: block;
  }
}

.desc {
  font-size: 1rem;
  text-align: justify;

  @media (--sm-viewport) {
    font-size: 1.2rem;
    text-align: left;
  }
}
