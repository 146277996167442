.input {
  width: 100%;
  height: 50px;

  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  z-index: 1;
}
