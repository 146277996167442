.fallback-message {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;

  background-color: rgba(0, 0, 0, .9);
  color: #f00;
  text-align: center;
  cursor: pointer;
  z-index: 100;
}
