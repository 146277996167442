@use "@scss/utils/utils.scss" as utils;

.footer {
  display: block;
  margin-top: 20px;
  background-color: var(--speclearn-grey-lighter, #f2f2f2);
  font-size: 1rem;
  line-height: 1.75rem;

  @include utils.fontFamily("Asap Condensed");
}

.information {
  padding-bottom: 20px;
}

.contact-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @each $type in (mail, mobile, phone, place) {
    &[data-type='#{$type}'] {
      background-image: url("./images/icon_#{$type}.svg");
    }
  }
}

.google-map {
  width: 100%;
  height: auto;
  position: relative;
}

.date-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 50px;
  padding: 15px 30px;
  background-color: var(--speclearn-grey-lighter, #f2f2f2);
  color: var(--speclearn-grey-light, #acacac);

  @media (--sm-viewport) {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.date-row__left,
.date-row__right {
  text-align: center;
}

.date-row__left {
  @media (--sm-viewport) {
    margin-right: 10px;
    text-align: right;
  }
}

.date-row__right {
  @media (--sm-viewport) {
    margin-left: 10px;
    text-align: left;
  }
}

@media (--md-viewport) {
  .information {
    flex-direction: row;
    justify-content: center;
  }

  .column {
    margin-top: 20px;
  }
}
