/* stylelint-disable selector-max-type -- Legacy body kezelés. */

@use "@scss/utils/utils.scss" as utils;

$border: 2px solid #000;

.signed-in {
  display: none;
}

.signed-out {
  display: none;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;
  border-top: $border;
  color: #fff;
  font-size: 1.2rem;
  z-index: 20;

  @include utils.fontFamily("Baloo");

  @media (--sm-viewport) {
    justify-content: flex-end;

    width: auto;

    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
    border-top: none;
    border-bottom: $border;
    border-left: $border;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 5px #ddd;
  }
}

body {
  &[data-signed="in"] .status {
    background-color: #1286c5;

    & .signed-in {
      display: block;

      @include utils.hover {
        cursor: pointer;
      }
    }
  }

  &[data-signed="out"] .status {
    background-color: #f79433;

    & .signed-out {
      display: block;
    }
  }
}
