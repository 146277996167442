.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  margin: 20px;
  padding: 35px;
  border: 1px solid #a0ce58;
  border-radius: 10px;
  background-color: #fff;
  font-size: 1.5rem;
}

/* stylelint-disable-next-line selector-max-type -- Legacy. */
body[data-signed="in"] .form {
  display: none;
}
