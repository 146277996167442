@use "@scss/utils/utils.scss" as utils;

.title {
  color: var(--green, rgb(160, 206, 88));
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;

  @include utils.fontFamily("Luckiest Guy");
}
