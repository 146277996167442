@use "@scss/utils/utils.scss" as utils;

.title {
  margin-top: 0;
  margin-bottom: .5rem;
  color: #a0ce58;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;

  @include utils.fontFamily("Baloo");

  @media (--sm-viewport) {
    font-size: 2rem;
  }
}
