@use "@scss/utils/utils.scss" as utils;

.button {
  width: 100%;
  height: 50px;

  margin-top: 10px;
  border-radius: 5px;
  background-color: #7ecdf7;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .3);
  transition: background-color 200ms;
  z-index: 1;

  white-space: nowrap;

  &.disabled {
    filter: grayscale(100%);
    pointer-events: none;
  }

  @include utils.fontFamily("Baloo");

  @include utils.hover {
    background-color: #f79433;
  }
}
