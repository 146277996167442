/* stylelint-disable selector-max-type -- Legacy, maradjon így az "a" és "img" miatt. */

@use "@scss/utils/utils.scss" as utils;

.card {
  width: 200px;
  min-height: 250px;

  position: relative;
  margin-top: 20px;
  padding: 10px;

  border: 1px solid #4caf50;
  border-radius: 10px;
  background-color: #fff;

  cursor: pointer;
  box-shadow: 0 3px rgba(0, 0, 0, .3);

  z-index: 1;

  &.demo {
    background-color: #cacaca;

    & img {
      filter: brightness(50%);
    }
  }

  @include utils.fontFamily("Luckiest Guy");

  @include utils.hover {
    border-color: #f79433;

    & p {
      color: #f79433;
    }
  }

  @media (--xxl-viewport) {
    width: 250px;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 100%;

  color: #3d3d3d;
  text-decoration: none;
}

.name {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
}

.serial {
  margin: 0 0 .2rem;
  font-size: 1rem;
  font-weight: 400;
}

.badge {
  position: absolute;
  top: 55px; /* pont a szériaszámra mutat */
  left: -8px;
  padding: 4px 8px;
  color: #fff;
  font-size: .875rem;
  font-weight: 700;
  z-index: 10;

  &[data-dev-phase="2"] {
    background-color: #f00;

    &::before {
      border-color: transparent #bc2525 #bc2525 transparent;
    }

    &::after {
      border-left: 15px solid #f00;
    }
  }

  &[data-dev-phase="1"] {
    background-color: #0b0;

    &::before {
      border-color: transparent #050 #050 transparent;
    }

    &::after {
      border-left: 15px solid #0b0;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
  }

  &::before {
    top: -8px;
    left: 0;
    border-width: 4px;
    border-style: solid;
  }

  &::after {
    top: 0;
    right: -15px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
}

.badge__text {
  position: relative;
  top: 2px;
}
