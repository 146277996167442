@use "@scss/utils/utils.scss" as utils;

.cell {
  display: flex;
  justify-content: center;
  position: relative; /* A DemoSign miatt */
  padding: 0;

  @include utils.hover {
    cursor: pointer;
  }
}
