@use "@scss/utils/utils.scss" as utils;

.subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  @media (--sm-viewport) {
    flex-direction: row;
    margin: 0;
  }
}

:global(.sni-subtitle__text) {
  margin: 10px;
  color: #6b6868;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;

  @include utils.fontFamily("Luckiest Guy");
}
