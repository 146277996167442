.error {
  width: 100%;

  margin-top: 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #721c24;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;

  &:not(:empty) {
    padding: 15px;
    border: 1px solid #f5c6cb;
  }
}
