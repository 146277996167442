/// Beállítja a megfelelő tartalmat, ha nem támogatott a touch.
/// @param {String} $selector [&] A megadott selector.
/// @content A megadott tartalom.
@mixin noTouchSupported ($selector: &) {
  @at-root {
    html:not(:global(.touch)) #{$selector} {
      @content;
    }
  }
}
