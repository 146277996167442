@use "@scss/utils/utils.scss" as utils;

$border: 2px solid #000;

.panel {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;

  border-top: 2px solid #000;

  background-color: #ef4524;
  color: #fff;
  z-index: 50;

  @include utils.fontFamily("Baloo");
}
