@use "@scss/utils/utils.scss" as utils;

/* stylelint-disable-next-line at-rule-disallowed-list -- Kell, hogy bekerüljön a reset. */
@import "~@root/css/reset/tags/input.css";

.input {
  --background-color: var(--t-bg-default);
  --color: var(--t-color-default);
  width: 100%;
  height: var(--t-input-height, 35px);

  padding: 0 6px;
  border: var(--t-border, 1px solid #ddd);
  border-radius: var(--t-border-radius, 2px);
  background-color: var(--background-color);

  color: var(--color);
  font-size: var(--t-font-size);

  &:focus {
    border-color: var(--t-color-focus);
  }

  &:disabled {
    background-color: #eee;
    cursor: not-allowed;
  }

  &[error],
  &[error]:focus {
    border: var(--t-state-border-error);
  }

  &[warning] {
    border: var(--t-state-border-warning);
  }

  &[success] {
    border: var(--t-state-border-success);
  }

  &[type="submit"] {
    /* stylelint-disable-next-line declaration-no-important -- Legacy. */
    background-color: var(--t-state-bg-info) !important;
    color: #fff;
  }

  @include utils.fontFamily(var(--t-font-family));
}
