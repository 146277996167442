:global(.sen-to-top) {
  display: none;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #118acb;

  color: #fff;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 200ms;

  z-index: 1;

  &[data-visible] {
    display: flex;
    opacity: 1;
  }
}
