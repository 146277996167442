@use "sass:list";
@use "sass:map";
@use "sass:string";

$fontFallbacks: (
  "Arial": "Helvetica, sans-serif",
  "Asap Condensed": "sans-serif",
  "Baloo": "cursive",
  "Dancing Script": "cursive",
  "Exo 2": "sans-serif",
  "Hun Script Basic": "sans-serif",
  "Luckiest Guy": "cursive",
  "Mouse Memoirs": "sans-serif",
  "Nova Flat": "cursive",
  "Orbitron": "sans-serif",
  "Oswald": "sans-serif",
  "Peralta": "cursive",
  "Roboto": "sans-serif",
  "Roboto Condensed": "sans-serif",
  "Roboto Slab": "serif",
  "SHPinscher": "sans-serif",
  "Sigmar One": "cursive",
  "Vollkorn": "sans-serif",
  "Baloo Da 2": "cursive",
  "Helvetica": "sans-serif",
  "Space Mono": "serif",
  "Open Sans": "sans-serif",
  "Arima": "sans-serif",
  "Playwrite DE Grund": "sans-serif",
  "Lora": "sans-serif"
);

$fontFamilies: (
  "Arial", "Asap Condensed", "Baloo", "Dancing Script", "Exo 2",
  "Hun Script Basic", "Luckiest Guy", "Mouse Memoirs",
  "Nova Flat", "Orbitron", "Oswald", "Peralta", "Roboto Condensed", "Roboto Slab",
  "SHPinscher", "Sigmar One", "Vollkorn", "Baloo Da 2", "Helvetica", "Roboto", "Space Mono",
  "Open Sans", "Arima", "Playwrite DE Grund", "Lora"
);

/// Beállítja a megadott font family-t.
/// @param {String} $family A megadott font family.
/// @param {Bool} $strict [true] Strict-en kell vizsgálni a font-family-t.
@mixin fontFamily ($family, $strict: true) {
  & {
    // Strict módban engedjük a var-t is.
    @if $strict == true {
      // @DEPRECATED: nem kéne a rendszerben var-t használni fontokra.
      @if string.slice($family, 1, 4) == "var(" {
        font-family: $family;
      }
      // Megvan a font.
      @else if list.index($fontFamilies, $family) {
        // Nem lehet felsorolás, csak a rendes fontnév.
        @if string.index($family, ",") {
          @error "[GOKU] #{$family} nem tartalmazhat felsorolást!";
        }

        $fallback: map.get($fontFallbacks, $family);

        @if not $fallback {
          @error "[GOKU] #{$family}-hez nincs fallback felvéve!";
        }

        font-family: #{$family}, #{$fallback};
      }
      @else {
        @error "[GOKU] #{$family} nincs a választható fontok között!";
      }
    }
    // Ha nem strict, akkor bármilyen fontot engedünk - NEM BIZTONSÁGOS!
    @else {
      font-family: $family;
    }
  }
}
