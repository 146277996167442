@use "@scss/utils/utils.scss" as utils;

.demo-sign {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  position: absolute;
  top: 10px;
  right: 5px;
  border-radius: 50%;
  background-color: var(--speclearn-orange, #f79433);

  color: var(--speclearn-white, #fff);
  font-size: 1rem;
  box-shadow: 2px 2px #b96f26;
  transform: rotate(10deg);
  transform-origin: center center;

  z-index: 3;

  @include utils.fontFamily(var(--speclearn-font-family-subtitle, "Luckiest Guy"));
}
