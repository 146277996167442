input {
  border: none;
  outline: 0;
  background-color: transparent;
  background-image: none;
  text-overflow: ellipsis;
  box-shadow: none;

  vertical-align: middle;

  &:focus {
    outline: 0 none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}
