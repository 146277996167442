button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: none;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  text-transform: none;
  box-shadow: none;
  overflow: visible;

  &,
  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    /* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated -- Nem merem autora tenni. */
    appearance: button;
  }

  &::-moz-focus-inner,
  &:focus,
  &[type="button"]::-moz-focus-inner,
  &[type="reset"]::-moz-focus-inner,
  &[type="submit"]::-moz-focus-inner {
    outline: none !important;
  }

  &:-moz-focusring,
  &[type="button"]:-moz-focusring,
  &[type="reset"]:-moz-focusring,
  &[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  &:not([disabled]):hover {
    cursor: pointer;
  }
}
