@use "@scss/utils/utils.scss" as utils;

.daily-code {
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 2px solid #000;
  font-size: 2rem;
  text-align: center;
  user-select: text;

  @media (--md-viewport) {
    border-top: none;
  }
}

.title {
  color: #1286c5;
  text-transform: uppercase;

  @include utils.fontFamily("Luckiest Guy");
}

.code {
  font-weight: 700;

  @include utils.fontFamily("Asap Condensed");
}

.floating-code {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  border-bottom-right-radius: 10px;
  background-color: #1286c5;
  color: #fff;
  font-size: 1.2rem;
  box-shadow: 5px 5px 5px #ddd;
  transform: translateY(-100px);
  transition: transform 200ms;
  z-index: 20;

  &.visible {
    transform: translateY(0);
  }

  @include utils.fontFamily("Baloo");
}

.container {
  position: relative;
  top: -2px;
  padding: 20px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #fff;
  box-shadow: 5px 5px 5px #ddd;
}
