/* stylelint-disable selector-max-type -- Legacy. */

:global(.working-screen__black) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .9);

  color: #fff;
  z-index: 9999; /* Ez legyen mindennél magasabb. */

  & h2,
  & h3 {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  @media (--md-viewport) {
    & h2 {
      font-size: 4rem;
    }

    & h3 {
      font-size: 2rem;
    }
  }
}

:global(.working-screen__title) {
  transition: color var(--sen-duration, 500ms);
}

:global(.working-screen__circle) {
  display: inline-block;
  transform: translateZ(1px);
}

:global(.working-screen__boring) {
  display: inline-block;

  width: 250px;
  height: 250px;

  margin: 60px;
  border-radius: 50%;
  background-size: contain;
  background-image: url("./images/boring.svg");
  background-repeat: no-repeat;
}
